import React, { useEffect, useRef, useState } from "react";
import { Upload, message, Badge, Modal, UploadFile } from "antd";
import firebase from "../../firebase";
import AttachmentCard from "./AttachmentCard";
import attach_blue from "../../images/little_attach_blue.svg";
import { newTimestamp } from "../utils";
import { useMediaQuery } from "react-responsive";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import "./Attachments.scss";
import { v4 as uuidv4 } from "uuid";

function beforeUpload(file) {
  /*
  Use this if we ever want to stop supporting certain formats

  const isJPG = file.type === 'image/jpg';
  const isPNG = file.type === 'image/png';
  const isJPEG = file.type === 'image/jpeg';
  const isGIF = file.type === 'image/gif';
  const isWebp = file.type === 'image/webp';
  const isSVG = file.type === 'image/svg+xml';

  return new Promise((resolve, reject) => {
    if(isJPG || isPNG || isJPEG || isGIF) {
      resolve(file);
    } else {
      message.error('Filetype not supported');
      reject(file);
    }
  });
  */

  return new Promise((resolve, reject) => {
    const isLt2M = file.size / 1024 / 1024 < 130;

    if (!isLt2M) {
      message.error("File must smaller than 130MB!");
      reject(file);
    } else {
      resolve(file);
    }
  });
}

export type Attachment = {
  created: any;
  id: string;
  name: string;
  size: number;
  url: string;
  user: string;
};

type CustomFile = {
  uid: string;
  name: string;
  size: number;
  type: string;
  originFileObj: File;
  status?: "uploading" | "done" | "error";
  percent?: number;
  response?: any;
  error?: any;
};

interface AttachmentModalProps {
  attachments?: Attachment[];
  projectId: string;
  updateAttachments: (attachments: Attachment[]) => void;
  type: "table" | "card" | "threads";
  filesToUpload?: File[];
  setFilesToUpload?: React.Dispatch<React.SetStateAction<File[]>>;
  droppedFiles?: File[];
  setDroppedFiles?: React.Dispatch<React.SetStateAction<File[]>>;
}

export default function AttachmentModal({
  attachments = [],
  projectId,
  updateAttachments,
  type,
  filesToUpload,
  setFilesToUpload,
  droppedFiles,
  setDroppedFiles,
}: AttachmentModalProps) {
  const is13Inch = useMediaQuery({ maxWidth: 1500 });

  const [fileList, setFileList] = useState<File[]>([]);
  const [filesBeingUploaded, setFilesBeingUploaded] = useState(0);
  const [modalVisible, setModalVisible] = useState(false);
  const currentAttachmentsRef = useRef<Attachment[]>(attachments);
  const currentUser = useCurrentUser();
  const [uploadProgress, setUploadProgress] = useState({});
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);

  useEffect(() => {
    currentAttachmentsRef.current = attachments;
  }, [attachments]);

  function firebaseStorageUpload({
    file,
    onSuccess,
    onError,
    onProgress,
  }: {
    file: any;
    onSuccess: (response: any) => void;
    onError: (error: any) => void;
    onProgress: (progress: any) => void;
  }) {
    // Create a root reference
    var storageRef = firebase.storage().ref();

    var randomId =
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15);

    var uploadTask = storageRef
      .child("projects/" + projectId + "/" + randomId + file.name)
      .put(file);

    // Register three observers:
    // 1. 'state_changed' observer, called any time the state changes
    // 2. Error observer, called on failure
    // 3. Completion observer, called on successful completion

    uploadTask.on(
      "state_changed",
      function (snapshot) {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        onProgress({ percent: progress });
        switch (snapshot.state) {
          case firebase.storage.TaskState.PAUSED: // or 'paused'
            break;
          case firebase.storage.TaskState.RUNNING: // or 'running'
            break;
        }
      },
      function (error) {
        // Handle unsuccessful uploads

        onError(error);
      },
      function () {
        // Handle successful uploads on complete
        // For instance, get the download URL: https://firebasestorage.googleapis.com/...
        uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
          onSuccess({
            url: downloadURL,
            id: randomId + file.name,
            size: uploadTask.snapshot.totalBytes,
          });
        });
      }
    );
  }

  function addAttachment(attachment: Attachment) {
    message.success(`${attachment.name} file uploaded successfully.`);

    var newFilesBeingUploaded = filesBeingUploaded - 1;
    setFilesBeingUploaded(newFilesBeingUploaded);

    if (newFilesBeingUploaded == 0) {
      //   setFileList([]);
    }

    // Remove it from the file list
    var newFileList =
      fileList &&
      fileList.filter((file: any) => {
        return file.name != attachment.name;
      });
    setFileList(newFileList);

    var attachmentsTemp: Attachment[] = [...currentAttachmentsRef.current];

    attachmentsTemp.push(attachment);

    // Update both the ref and call updateAttachments
    currentAttachmentsRef.current = attachmentsTemp;
    updateAttachments(attachmentsTemp);
  }

  function fileChanged(info) {
    const status = info.file.status;

    setFileList(info.fileList);

    if (status !== "uploading") {
    }
    if (status === "done") {
      var attachment = {
        created: newTimestamp(),
        id: info.file.response.id,
        size: info.file.response.size,
        name: info.file.name,
        user: currentUser.id,
        url: info.file.response.url,
      };

      addAttachment(attachment);
    } else if (status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  }

  function handleCancel(e) {
    setModalVisible(false);
  }

  const processAndUploadFiles = (
    newFiles: File[] | CustomFile[],
    fileType: "selected" | "dropped" | "pasted",
    clearFiles: (() => void) | undefined
  ) => {
    try {
      if (newFiles && newFiles.length > 0) {
        // we need to make sure the files are not over the limit of 130MB
        const totalSize = newFiles.reduce((acc, file) => acc + file.size, 0);

        if (totalSize / 1024 / 1024 > 130) {
          message.error("Total file size exceeds 130MB");
          return;
        }

        let newCustomFiles: CustomFile[] = newFiles.map((file, index) => ({
          uid: `${fileType}-${index}`,
          name: file.name,
          size: file.size,
          type: file.type,
          originFileObj: file,
        }));

        const uploadPromises = newCustomFiles.map(
          (file) =>
            new Promise((resolve, reject) => {
              firebaseStorageUpload({
                file: file.originFileObj,
                onSuccess: (response) => {
                  fileChanged({ file: { ...file, status: "done", response } });
                  setUploadProgress((prev) => ({ ...prev, [file.uid]: 100 }));
                  resolve(response);
                },
                onError: (error) => {
                  fileChanged({ file: { ...file, status: "error", error } });
                  setUploadProgress((prev) => ({ ...prev, [file.uid]: 0 }));
                  reject(error);
                },
                onProgress: ({ percent }) => {
                  setUploadProgress((prev) => ({
                    ...prev,
                    [file.uid]: percent,
                  }));
                  fileChanged({
                    file: { ...file, status: "uploading", percent },
                  });
                },
              });
            })
        );

        Promise.all(uploadPromises)
          .then((responses) => {
            if (clearFiles) clearFiles();
          })
          .catch((error) => {
            console.error("Error uploading files:", error);
          });
      }
    } catch (error) {
      console.error("Error processing and uploading files:", error);
    }
  };

  useEffect(() => {
    if (filesToUpload && setFilesToUpload) {
      processAndUploadFiles(filesToUpload, "pasted", () =>
        setFilesToUpload([])
      );
    }
  }, [filesToUpload]);

  useEffect(() => {
    if (droppedFiles && setDroppedFiles) {
      processAndUploadFiles(droppedFiles, "dropped", () => setDroppedFiles([]));
    }
  }, [droppedFiles]);

  const handleFileSelect = (files: File[]) => {
    setSelectedFiles(files);
    processAndUploadFiles(files, "selected", () => setSelectedFiles([]));
  };

  if (type == "table") {
    return (
      <div className="table-attachments">
        {attachments != null && attachments.length != 0 && (
          <div onClick={() => setModalVisible(true)} className="view-files">
            View Files{" "}
            {is13Inch && (
              <Badge
                style={{
                  backgroundColor: "#353535",
                  color: "#ffffff",
                  fontSize: "12px",
                }}
                count={attachments.length}
              />
            )}
            {!is13Inch && (
              <Badge
                style={{ backgroundColor: "#353535", color: "#ffffff" }}
                count={attachments.length}
              />
            )}
          </div>
        )}
        <Modal
          title="Attachments"
          footer={null}
          visible={modalVisible}
          onCancel={handleCancel}
        >
          <div>
            {attachments.map((attachment: any) => {
              return (
                <AttachmentCard
                  key={attachment.id}
                  attachment={attachment}
                  attachments={attachments}
                  projectId={projectId}
                  updateAttachments={updateAttachments}
                />
              );
            })}
            {/* @ts-ignore */}
            <Upload
              name="file"
              multiple={true}
              beforeUpload={beforeUpload}
              customRequest={firebaseStorageUpload}
              onChange={fileChanged}
              fileList={fileList}
            >
              <div
                style={{
                  marginTop: "20px",
                  fontSize: "14px",
                  marginLeft: "5px",
                  fontFamily: "Avenir Next",
                  color: "#1563FF",
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                <img
                  src={attach_blue}
                  style={{
                    height: "16px",
                    marginRight: "5px",
                  }}
                />

                <div
                  style={{
                    fontWeight: 500,
                    marginRight: "5px",
                    cursor: "pointer",
                  }}
                >
                  Add Files
                </div>

                {attachments != null && attachments.length != 0 && (
                  <Badge
                    style={{ backgroundColor: "#1563FF", color: "#ffffff" }}
                    count={attachments.length}
                  />
                )}
              </div>
            </Upload>
          </div>
        </Modal>

        {/* @ts-ignore */}
        <Upload
          name="file"
          multiple={true}
          beforeUpload={beforeUpload}
          customRequest={firebaseStorageUpload}
          onChange={fileChanged}
          fileList={fileList}
        >
          <div
            style={{
              fontSize: "14px",

              fontFamily: "Avenir Next",
              color: "#1563FF",
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            <img
              src={attach_blue}
              style={{
                height: "16px",
                marginRight: "5px",
              }}
            />

            <div
              style={{ fontWeight: 500, marginRight: "5px", cursor: "pointer" }}
            >
              Add Files
            </div>
          </div>
        </Upload>
      </div>
    );
  } else {
    return (
      <div>
        {/* @ts-ignore */}
        {/* <Upload
          name="file"
          multiple={true}
          beforeUpload={beforeUpload}
          customRequest={firebaseStorageUpload}
          onChange={fileChanged}
          fileList={fileList}
        >
          <div
            style={{
              marginTop: "10px",
              fontSize: "14px",
              marginLeft: "5px",
              fontFamily: "Avenir Next",
              color: "#1563FF",
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            <img
              src={attach_blue}
              style={{
                height: "16px",
                marginRight: "5px",
              }}
            />

            <div
              style={{ fontWeight: 500, marginRight: "5px", cursor: "pointer" }}
            >
              Add Filess
            </div>

            {attachments && (
              <Badge
                style={{ backgroundColor: "#1563FF", color: "#ffffff" }}
                count={attachments.length}
              />
            )}
          </div>
        </Upload> */}
        <FileUploader onFileSelect={handleFileSelect} />

        {selectedFiles &&
          selectedFiles.length > 0 &&
          selectedFiles.map((file, index) => {
            if (uploadProgress[`selected-${index}`] === 100) return null;
            return (
              <div key={`selected-${index}`} style={{ marginTop: 16 }}>
                <div>{file.name}</div>
                <UploadProgressBar
                  percent={uploadProgress[`selected-${index}`] || 0}
                />
              </div>
            );
          })}

        {droppedFiles &&
          droppedFiles.map((file, index) => {
            if (uploadProgress[`dropped-${index}`] === 100) return null;
            return (
              <div key={`dropped-${index}`} style={{ marginTop: 16 }}>
                <div>{file.name}</div>
                <UploadProgressBar
                  percent={uploadProgress[`dropped-${index}`] || 0}
                />
              </div>
            );
          })}

        {filesToUpload &&
          filesToUpload.map((file, index) => {
            if (uploadProgress[`pasted-${index}`] === 100) return null;
            return (
              <div key={`pasted-${index}`} style={{ marginTop: 16 }}>
                <div>{file.name}</div>
                <UploadProgressBar
                  percent={uploadProgress[`pasted-${index}`] || 0}
                />
              </div>
            );
          })}

        {attachments.map((attachment: any) => (
          <AttachmentCard
            key={attachment.id}
            attachment={attachment}
            attachments={attachments}
            projectId={projectId}
            updateAttachments={updateAttachments}
          />
        ))}
      </div>
    );
  }
}

const UploadProgressBar = ({ percent }: { percent: number }) => {
  return (
    <div
      style={{
        width: "100%",
        backgroundColor: "#e0e0e0",
        borderRadius: 4,
        marginTop: 8,
      }}
    >
      <div
        style={{
          width: `${percent}%`,
          backgroundColor: "#1563FF",
          height: 8,
          borderRadius: 4,
          transition: "width 0.3s ease-in-out",
        }}
      />
    </div>
  );
};

const FileUploader = ({ onFileSelect }) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      onFileSelect(Array.from(e.target.files));
    }
  };

  return (
    <div
      onClick={() => fileInputRef.current?.click()}
      className="mt-2.5 ml-1.5 text-sm font-['Avenir_Next'] text-[#1563FF] flex items-center cursor-pointer max-w-fit"
    >
      <img src={attach_blue} className="h-4 mr-1.5" alt="Attach file" />
      <div className="font-medium mr-1.5 cursor-pointer">Add Files</div>
      <input
        type="file"
        ref={fileInputRef}
        className="hidden"
        onChange={handleFileSelect}
        multiple
      />
    </div>
  );
};
