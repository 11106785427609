import React, { useState, useRef, useEffect, ReactElement } from "react";
import { isMobile } from "../utils";
import { Drawer } from "antd";
import { useMediaQuery } from "react-responsive";

interface CommentDrawerProps {
  title: string;
  onClose: () => void;
  visible: boolean;
  children: React.ReactNode;
  fileDragDropEnabled?: boolean;
}

interface ChildProps {
  droppedFiles?: File[];
  setDroppedFiles?: (files: File[]) => void;
}

export function CommentDrawer({
  title,
  onClose,
  visible,
  children,
  fileDragDropEnabled = false,
}: CommentDrawerProps) {
  const is13Inch = useMediaQuery({ maxWidth: 1500 });
  const [isDragging, setIsDragging] = useState(false);
  const dropRef = useRef<HTMLDivElement>(null);
  const [droppedFiles, setDroppedFiles] = useState<File[]>([]);
  const dragCounter = useRef(0);

  const onFileDrop = (files: File[]) => {
    setDroppedFiles(files);
  };

  useEffect(() => {
    if (!fileDragDropEnabled) return;

    const dropArea = dropRef.current;
    if (!dropArea) return;

    const handleDragOver = (e: DragEvent) => {
      e.preventDefault();
      e.stopPropagation();
    };

    const handleDragEnter = (e: DragEvent) => {
      e.preventDefault();
      e.stopPropagation();
      dragCounter.current++;
      if (dragCounter.current === 1) {
        setIsDragging(true);
      }
    };

    const handleDragLeave = (e: DragEvent) => {
      e.preventDefault();
      e.stopPropagation();
      dragCounter.current--;
      if (dragCounter.current === 0) {
        setIsDragging(false);
      }
    };

    const isFolder = (file: File): boolean => {
      // Check if it's likely a folder
      if (file.name.indexOf(".") === -1 && file.type === "") {
        return true;
      }

      // Additional check for macOS which might set a small non-zero size for folders
      if (file.size <= 4096 && file.type === "") {
        return true;
      }

      return false;
    };

    const handleDrop = (e: DragEvent) => {
      e.preventDefault();
      e.stopPropagation();
      setIsDragging(false);
      dragCounter.current = 0;

      if (e.dataTransfer) {
        const allFiles = Array.from(e.dataTransfer.files) as File[];
        const validFiles = allFiles.filter((file) => !isFolder(file));

        if (validFiles.length > 0) {
          onFileDrop(validFiles);
        }
      }
    };

    document.addEventListener("dragenter", handleDragEnter);
    document.addEventListener("dragleave", handleDragLeave);
    document.addEventListener("dragover", handleDragOver);
    document.addEventListener("drop", handleDrop);

    return () => {
      document.removeEventListener("dragenter", handleDragEnter);
      document.removeEventListener("dragleave", handleDragLeave);
      document.removeEventListener("dragover", handleDragOver);
      document.removeEventListener("drop", handleDrop);
    };
  }, [fileDragDropEnabled, onFileDrop]);

  // Clone children and pass droppedFiles
  const childrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child as ReactElement<ChildProps>, {
        droppedFiles,
        setDroppedFiles,
      });
    }
    return child;
  });

  return (
    <Drawer
      width={isMobile() ? "100%" : is13Inch ? "550px" : "650px"}
      drawerStyle={{
        backgroundColor: "#FBFAF9",
        borderRadius: "13px",
        position: "relative",
      }}
      headerStyle={{
        backgroundColor: "#FBFAF9",
      }}
      bodyStyle={{ padding: "20px" }}
      title={title}
      placement="right"
      closable={true}
      onClose={onClose}
      open={visible}
    >
      <div
        ref={dropRef}
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: isDragging ? 1 : -1,
          backgroundColor: isDragging ? "rgba(0,0,0,0.1)" : "transparent",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          transition: "background-color 0.3s",
        }}
      >
        {isDragging && (
          <div className="absolute bg-[#FBFAF9]/95 h-full w-full flex items-center justify-center flex-col">
            <img
              src={require("../../images/drag_file.png")}
              alt="drag file"
              className="h-12 w-12"
            />
            <div className="text-base font-medium">Drop files here</div>
          </div>
        )}
      </div>
      {childrenWithProps}
    </Drawer>
  );
}
