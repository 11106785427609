import React, { useState } from "react";
import styled from "styled-components";
import { clearFix } from "polished";
import { Button, Avatar, Modal } from "antd";
import AttachmentModal from "../AttachmentModal";
import TextField from "./TextField";

import DefaultAvatar from "../../images/default-avatar.png";

export default function AddComment({
  onClick,
  onChange,
  value,
  projectId,
  currentUser,
  attachments,
  updateAttachments,
  visible,
  droppedFiles,
  setDroppedFiles,
}) {
  // const is13Inch = useMediaQuery({ maxWidth: 1500 });

  const [attachmentsVisible, setAttachmentsVisible] = useState(false);
  const [filesToUpload, setFilesToUpload] = useState([]);

  function showAttachments() {
    setAttachmentsVisible(true);
  }

  function handleCancelAttachments(e) {
    setAttachmentsVisible(false);
  }

  function addAttachmentToObject(attachment) {
    var attachments = attachments;
    attachments.push(attachment);

    updateAttachments(attachments);
  }

  var name = currentUser.name;
  var initials = name.match(/\b\w/g) || [];
  initials = ((initials.shift() || "") + (initials.pop() || "")).toUpperCase();

  return (
    <div>
      <Modal
        visible={attachmentsVisible}
        centered
        onCancel={handleCancelAttachments}
        footer={null}
        title={null}
        bodyStyle={{ paddingBottom: "30px" }}
        closable={false}
      >
        <AttachmentContainer>
          <AttachmentLabel>Add Files</AttachmentLabel>
          <AttachmentModal
            attachments={attachments}
            projectId={projectId}
            type={"threads"}
            updateAttachments={updateAttachments}
            filesToUpload={filesToUpload}
          />
        </AttachmentContainer>
      </Modal>

      <Content>
        <ImageContainer>
          {currentUser?.image && (
            <UserImg
              onError={(ev) => (ev.target.src = DefaultAvatar)}
              src={currentUser.image}
            />
          )}

          {!currentUser?.image && <AvatarImg size="40px">{initials}</AvatarImg>}
        </ImageContainer>
        <PostContainer>
          <TextField
            placeholder="Add a comment"
            onChange={onChange}
            value={value}
            autoSize={{ minRows: 2, maxRows: 6 }}
            visible={visible}
            filesToUpload={filesToUpload}
            setFilesToUpload={setFilesToUpload}
          />

          {!value && !(attachments?.length > 0) ? (
            <DisabledButton onClick={onClick} type="primary" disabled>
              Post
            </DisabledButton>
          ) : (
            <EnabledButton onClick={onClick} type="primary">
              Post
            </EnabledButton>
          )}

          <ThreadAttachmentModal
            attachments={attachments}
            projectId={projectId}
            currentUser={currentUser}
            type={"threads"}
            addAttachmentToObject={addAttachmentToObject}
            updateAttachments={updateAttachments}
            filesToUpload={filesToUpload}
            setFilesToUpload={setFilesToUpload}
            droppedFiles={droppedFiles}
            setDroppedFiles={setDroppedFiles}
          />
        </PostContainer>
      </Content>
    </div>
  );
}

const AttachmentContainer = styled.div`
  flex: 6;
  text-align: left;
`;

const AttachmentLabel = styled.div`
  color: #5f5f5f;
  fontsize: 20px;
  font-weight: 500;
  font-family: Avenir Next;
  margin-bottom: 10px;
`;

const Content = styled.div`
  margin-top: 30px;
  margin-bottom: 20px;
  display: flex;
`;

const ImageContainer = styled.div`
  vertical-align: top;
`;

const UserImg = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
`;

const AvatarImg = styled(Avatar)`
  color: #f56a00;
  background-color: #fde3cf;
  vertical-align: middle;
`;

const PostContainer = styled.div`
  width: 100%;
  vertical-align: middle;
  text-align: left;
  margin-left: 10px;
`;

const DisabledButton = styled(Button)`
  margin-top: 10px;
  float: right;
  color: #ffffff !important;
  opacity: 0.6;
  background-color: #1c67ff !important;
  font-family: Avenir Next;
  border-color: #1c67ff;
`;

const EnabledButton = styled(Button)`
  margin-top: 10px;
  float: right;
  background-color: #1c67ff;
  font-family: Avenir Next;
  border-color: #1c67ff;
`;

const ThreadAttachmentModal = styled(AttachmentModal)`
  ${clearFix()}
`;
