import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Input, Button, Select, Checkbox } from "antd";
import _ from "lodash";
import { id } from "../utils";
import { useNavigate } from "react-router-dom";
import { loadProjectsForUser } from "../../actions/project";

import actions from "../../actions";
const { Option } = Select;
const algoliasearch = require("algoliasearch");

// configure algolia
const algolia = algoliasearch(
  process.env.REACT_APP_ALGOLIA_PROJECT_ID,
  process.env.REACT_APP_ALGOLIA_ADMIN_KEY
);

const ALOA_ADMIN_IDS = ["ISdz3WW5m6PNTpUjbsXo3vaMCuG3", "bM2j3eZl6kMIfIBQTf64v0pZkHt1", "iv59Rp58c6fVnWUtrSr9fVyIGYc2", "auth0|6087153b8fbdf60068fffe29", "auth0|646e24dfdb7f2562866149ac", "auth0|5fc7ab645bddd2006e27bca3", "auth0|66e46bdd9a7c0236b59d1782"];

export default function ProjectCreationModal({ closeModal, currentUser }) {
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [projectName, setProjectName] = useState("");
  const [addAloaAdmins, setAddAloaAdmins] = useState(false);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const handleCreation = async () => {
    setConfirmLoading(true);

    var tempId = id();

    var tempProject = {
      name: projectName,
      active: true,
      addonsEnabled: [
        'files',
        'documentations',
        'meeting_minutes',
      ],
      archived: false,
      date_started: new Date(),
      slackHooks: [],
      milestonesOrder: [],
      collaborators: [
        {
          user: currentUser.id,
          role: "admin",
        },
      ],
      id: tempId,
      collaborator_ids: [currentUser.id],
      _nonAloa:
        currentUser.email &&
          (currentUser.email.includes("@aloa.co") ||
            currentUser.email.includes("@aloalabs.com"))
          ? false
          : true,
      performance_reports_enabled: true,
    };

    if (addAloaAdmins) {
      const filteredAdminIds = ALOA_ADMIN_IDS.filter(id => id !== currentUser.id);
      tempProject.collaborators = [...tempProject.collaborators, ...filteredAdminIds.map(id => ({ user: id, role: "admin" }))];
      tempProject.collaborator_ids = [...tempProject.collaborator_ids, ...filteredAdminIds];
    }

    await dispatch(actions.project.addProject(tempProject));
    dispatch(loadProjectsForUser(currentUser.id));

    navigate(`p/${tempId}/table/status_items`);

    setTimeout(() => {
      setConfirmLoading(false);
    }, 2000);
  };

  return (
    <div style={{ padding: "10px" }}>
      <div style={{ fontSize: "18px", marginBottom: "10px" }}>New Project</div>
      <Input
        value={projectName}
        style={{ marginBottom: "20px" }}
        placeholder="Project Name"
        onChange={(event) => setProjectName(event.target.value)}
      />

      <Checkbox
        checked={addAloaAdmins}
        onChange={(e) => setAddAloaAdmins(e.target.checked)}
        style={{ marginRight: "10px" }}
      >
        Add Aloa Admins
      </Checkbox>

      <div style={{ float: "right" }}>
        <Button key="back" onClick={closeModal}>
          Cancel
        </Button>

        <Button
          style={{ marginLeft: "10px" }}
          key="submit"
          type="primary"
          loading={confirmLoading}
          onClick={handleCreation}
        >
          Create Project
        </Button>
      </div>
      <div style={{ clear: "right" }}> </div>
    </div>
  );
}