import { AppState } from "@/types";
import firebase, { db } from "../firebase";
import { Report, ReportNotification } from "../types/reports";
import { asyncThunkCreator, buildCreateSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { Roles } from "@/types/roles";

export const createAppSlice = buildCreateSlice({
  creators: { asyncThunk: asyncThunkCreator },
});

export const reportsSlice = createAppSlice({
  name: "reports",
  initialState: {
    loading: false,
    data: {},
    notifications: {},
  },
  reducers: (create) => ({
    fetchReportNotificationsForUser: create.asyncThunk(
      async (userId: string) => {
        const data = await db
          .collectionGroup("reportNotifications")
          .where("unreadUsers", "array-contains", userId)
          .get();

        // Return an object where key is doc.id and value id the data

        var tempObject = {};

        data.docs.forEach((doc) => {
          tempObject[doc.id] = doc.data();
        });

        return tempObject;
      },
      {
        pending: (state) => {
          state.loading = true;
        },
        rejected: (state, action) => {
          state.loading = false;
        },
        fulfilled: (state, action) => {
          state.loading = false;
          state.notifications = {
            ...state.notifications,
            ...action.payload,
          };
        },
      }
    ),
    fetchReportNotificationsForProject: create.asyncThunk(
      async (projectId: string) => {
        const data = await db
          .collection("projects")
          .doc(projectId)
          .collection("reportNotifications")
          .get();

        return Object.fromEntries(data.docs.map((doc) => [doc.id, doc.data()]));
      },
      {
        pending: (state) => {
          state.loading = true;
        },
        rejected: (state, action) => {
          state.loading = false;
        },
        fulfilled: (state, action) => {
          state.loading = false;
          state.notifications = {
            ...state.notifications,
            ...action.payload,
          };
        },
      }
    ),
    fetchReportsForProject: create.asyncThunk(
      async (projectId: string) => {
        const data = await db
          .collection("projects")
          .doc(projectId)
          .collection("reports")
          .where("status", "==", "sent")
          .get();


        return data.docs
          .filter((doc) => doc.data().deleted !== true)
          .map((doc) => ({ id: doc.id, ...doc.data() } as Report));
      },
      {
        pending: (state) => {
          state.loading = true;
        },
        rejected: (state, action) => {
          state.loading = false;
        },
        fulfilled: (state, action) => {
          state.loading = false;
          state.data = {
            ...state.data,
            [action.meta.arg]: Object.fromEntries(
              action.payload
                .sort(
                  (a, b) =>
                    new Date(b.date).getTime() - new Date(a.date).getTime()
                )
                .map((report) => [report.id, report])
            ),
          };
        },
      }
    ),
    updateReport: create.asyncThunk(
      async (report: Report) => {
        await db
          .collection("projects")
          .doc(report.projectId)
          .collection("reports")
          .doc(report.id)
          .set(report);

        return report;
      },
      {
        fulfilled: (state, action) => {
          state.loading = false;
          state.data[action.payload.projectId][action.payload.id] =
            action.payload;
        },
      }
    ),
    updateReportAsSeen: create.asyncThunk(
      async ({ report, userId }: { report: Report; userId: string }) => {
        const updatedReport: Report = _.cloneDeep(report);

        updatedReport.seenBy = [
          ...(updatedReport?.seenBy ?? []),
          {
            user: userId,
            date: new Date().toISOString(),
          },
        ];

        await db
          .collection("projects")
          .doc(report.projectId)
          .collection("reports")
          .doc(report.id)
          .update({
            seenBy: firebase.firestore.FieldValue.arrayUnion({
              user: userId,
              date: new Date().toISOString(),
            }),
          });

        return updatedReport;
      },
      {
        fulfilled: (state, action) => {
          state.loading = false;
          state.data[action.payload.projectId][action.payload.id] =
            action.payload;
        },
      }
    ),
    deleteReport: create.asyncThunk(
      async ({ reportId, projectId }: { reportId: string; projectId: string }) => {
        await db
          .collection("projects")
          .doc(projectId)
          .collection("reports")
          .doc(reportId)
          .update({
            deleted: true,
          });

        return { reportId, projectId };
      },
      {
        fulfilled: (state, action) => {
          const { reportId, projectId } = action.payload;
         // Delete the project
          delete state.data[projectId][reportId];
        },
      }
    ),
    updateReportThreadRead: create.asyncThunk(
      async ({ report, userId }: { report: Report; userId: string }) => {
        const updatedReport: Report = _.cloneDeep(report);

        updatedReport.thread_read_timesetamps = {
          ...updatedReport.thread_read_timesetamps,
          [userId]: new Date().toISOString(),
        };

        await db
          .collection("projects")
          .doc(report.projectId)
          .collection("reports")
          .doc(report.id)
          .update(updatedReport);

        return updatedReport;
      },
      {
        fulfilled: (state, action) => {
          state.loading = false;
          state.data[action.payload.projectId][action.payload.id] =
            action.payload;
        },
      }
    ),
    updateReportNotificationAsSeen: create.asyncThunk(
      async ({
        notification,
        userId,
      }: {
        notification: ReportNotification;
        userId: string;
      }) => {
        const updatedNotification: ReportNotification =
          _.cloneDeep(notification);

        updatedNotification.unreadUsers = [
          ...(updatedNotification?.unreadUsers?.filter(
            (entry) => entry !== userId
          ) ?? []),
        ];

        await db
          .collection("projects")
          .doc(notification.projectId)
          .collection("reportNotifications")
          .doc(notification.id)
          .update(updatedNotification);

        return updatedNotification;
      },
      {
        fulfilled: (state, action) => {
          state.loading = false;
          state.notifications[action.payload.id] = action.payload;
        },
      }
    ),
    updateReportAsDownloaded: create.asyncThunk(
      async ({ report, userId }: { report: Report; userId: string }) => {
        const updatedReport: Report = _.cloneDeep(report);

        const downloadDate = new Date().toISOString();

        updatedReport.downloadedBy = [
          ...(updatedReport?.downloadedBy ?? []),
          {
            user: userId,
            date: downloadDate,
          },
        ];

        await db
          .collection("projects")
          .doc(report.projectId)
          .collection("reports")
          .doc(report.id)
          .update({
            downloadedBy: firebase.firestore.FieldValue.arrayUnion({
              user: userId,
              date: downloadDate,
            }),
          });

        return updatedReport;
      },
      {
        fulfilled: (state, action) => {
          state.loading = false;
          state.data[action.payload.projectId][action.payload.id] =
            action.payload;
        },
      }
    ),
  }),
});

export const addReportPermissions = (roles: AppState["roles"]): Roles => {
  const newRoles = {
    admin: {
      ...roles.admin,
      permissions: {
        ...roles.admin.permissions,
        sections: {
          ...roles.admin.permissions.sections,
          reports: {
            create: true,
            delete: true,
            edit: true,
            comment: true,
            read: true,
          },
        },
      },
    },
    client: {
      ...roles.client,
      permissions: {
        ...roles.client.permissions,
        sections: {
          ...roles.client.permissions.sections,
          reports: {
            create: false,
            delete: false,
            edit: false,
            comment: true,
            read: true,
          },
        },
      },
    },
    vendor: {
      ...roles.vendor,
      permissions: {
        ...roles.vendor.permissions,
        sections: {
          ...roles.vendor.permissions.sections,
          reports: {
            create: false,
            delete: false,
            edit: false,
            comment: false,
            read: false,
          },
        },
      },
    },
    viewer: {
      ...roles.viewer,
      permissions: {
        ...roles.viewer.permissions,
        sections: {
          ...roles.viewer.permissions.sections,
          reports: {
            create: false,
            delete: false,
            edit: false,
            comment: false,
            read: true,
          },
        },
      },
    }
  };

  return newRoles;
};

export const reportsReducers = reportsSlice.reducer;
export const {
  fetchReportsForProject,
  updateReport,
  updateReportAsSeen,
  deleteReport,
  updateReportThreadRead,
  fetchReportNotificationsForUser,
  updateReportNotificationAsSeen,
  updateReportAsDownloaded,
  fetchReportNotificationsForProject,
} = reportsSlice.actions;

// Test Data

const testReport1: Report = {
  id: "test-report",
  projectId: "kbatx5i9s5yrieuz0jyx",
  type: "performance",
  title: "Performance Report",
  resultSummary: "10/12 checks passed",
  date: "2/8/2024",
  periodStart: "01/10/2024",
  periodEnd: "01/24/2024",
  lastUpdated: "2/10/2024",
  seenBy: [],
  items: [
    {
      id: "repo_ownership_setup",
      title: "Repo ownership/setup",
      description:
        "Client will host the repo on GitLab with a 3 repos for 1 frontend, 1 backed and 1 API",
      passed: true,
      actionItems: [],
    },
    {
      id: "standups",
      title: "Standups",
      description:
        "There should be one standup per working day that describes the work being done.",
      passed: false,
      actionItems: [
        "One standup was logged retroactively (more than a day late). Mobcoder has been informed of this and pledges to log standups on time moving forward.",
      ],
    },
    {
      id: "commit_frequency",
      title: "Commit Frequency",
      description: "At least 2 commits per day",
      passed: true,
      actionItems: [],
    },
    {
      id: "commit_messages",
      title: "Commit messages",
      description:
        "Commit messages should be descriptive enough to understand why the commit was made.",
      passed: true,
      actionItems: [],
    },
  ],
};

const testReport2 = {
  id: "test-report-2",
  projectId: "kbatx5i9s5yrieuz0jyx",
  type: "performance",
  title: "Performance Report",
  resultSummary: "8/12 checks passed",
  date: "2/8/2024",
  periodStart: "01/01/2024",
  periodEnd: "01/10/2024",
  lastUpdated: "2/10/2024",
  seenBy: [],
  items: [
    {
      id: "repo_ownership_setup",
      title: "Repo ownership/setup",
      description:
        "Client will host the repo on GitLab with a 3 repos for 1 frontend, 1 backed and 1 API",
      passed: true,
      actionItems: [],
    },
    {
      id: "standups",
      title: "Standups",
      description:
        "There should be one standup per working day that describes the work being done.",
      passed: false,
      actionItems: [
        "One standup was logged retroactively (more than a day late). Mobcoder has been informed of this and pledges to log standups on time moving forward.",
      ],
    },
    {
      id: "commit_frequency",
      title: "Commit Frequency",
      description: "At least 2 commits per day",
      passed: true,
      actionItems: [],
    },
    {
      id: "commit_messages",
      title: "Commit messages",
      description:
        "Commit messages should be descriptive enough to understand why the commit was made.",
      passed: true,
      actionItems: [],
    },
  ],
};

const testReport3 = {
  id: "test-report-3",
  projectId: "kbatx5i9s5yrieuz0jyx",
  type: "performance",
  title: "Performance Report",
  resultSummary: "6/12 checks passed",
  date: "2/8/2024",
  periodStart: "12/15/2023",
  periodEnd: "01/01/2024",
  lastUpdated: "2/10/2024",
  seenBy: [],
  items: [
    {
      id: "repo_ownership_setup",
      title: "Repo ownership/setup",
      description:
        "Client will host the repo on GitLab with a 3 repos for 1 frontend, 1 backed and 1 API",
      passed: true,
      actionItems: [],
    },
    {
      id: "standups",
      title: "Standups",
      description:
        "There should be one standup per working day that describes the work being done.",
      passed: false,
      actionItems: [
        "One standup was logged retroactively (more than a day late). Mobcoder has been informed of this and pledges to log standups on time moving forward.",
      ],
    },
    {
      id: "commit_frequency",
      title: "Commit Frequency",
      description: "At least 2 commits per day",
      passed: true,
      actionItems: [],
    },
    {
      id: "commit_messages",
      title: "Commit messages",
      description:
        "Commit messages should be descriptive enough to understand why the commit was made.",
      passed: true,
      actionItems: [],
    },
  ],
};

const testReport4 = {
  id: "test-report-4",
  projectId: "kbatx5i9s5yrieuz0jyx",
  type: "performance",
  title: "Performance Report",
  resultSummary: "4/12 checks passed",
  date: "2/8/2024",
  periodStart: "12/01/2023",
  periodEnd: "12/15/2023",
  lastUpdated: "2/10/2024",
  seenBy: [],
  items: [
    {
      id: "repo_ownership_setup",
      title: "Repo ownership/setup",
      description:
        "Client will host the repo on GitLab with a 3 repos for 1 frontend, 1 backed and 1 API",
      passed: true,
      actionItems: [],
    },
    {
      id: "standups",
      title: "Standups",
      description:
        "There should be one standup per working day that describes the work being done.",
      passed: false,
      actionItems: [
        "One standup was logged retroactively (more than a day late). Mobcoder has been informed of this and pledges to log standups on time moving forward.",
      ],
    },
    {
      id: "commit_frequency",
      title: "Commit Frequency",
      description: "At least 2 commits per day",
      passed: true,
      actionItems: [],
    },
    {
      id: "commit_messages",
      title: "Commit messages",
      description:
        "Commit messages should be descriptive enough to understand why the commit was made.",
      passed: true,
      actionItems: [],
    },
  ],
};

const testReport5 = {
  id: "test-report-5",
  projectId: "kbatx5i9s5yrieuz0jyx",
  type: "performance",
  title: "Performance Report",
  resultSummary: "2/12 checks passed",
  date: "2/8/2024",
  periodStart: "11/15/2023",
  periodEnd: "12/01/2023",
  lastUpdated: "2/10/2024",
  seenBy: [],
  items: [
    {
      id: "repo_ownership_setup",
      title: "Repo ownership/setup",
      description:
        "Client will host the repo on GitLab with a 3 repos for 1 frontend, 1 backed and 1 API",
      passed: true,
      actionItems: [],
    },
    {
      id: "standups",
      title: "Standups",
      description:
        "There should be one standup per working day that describes the work being done.",
      passed: false,
      actionItems: [
        "One standup was logged retroactively (more than a day late). Mobcoder has been informed of this and pledges to log standups on time moving forward.",
      ],
    },
    {
      id: "commit_frequency",
      title: "Commit Frequency",
      description: "At least 2 commits per day",
      passed: true,
      actionItems: [],
    },
    {
      id: "commit_messages",
      title: "Commit messages",
      description:
        "Commit messages should be descriptive enough to understand why the commit was made.",
      passed: true,
      actionItems: [],
    },
  ],
};

const testReport6 = {
  id: "test-report-6",
  projectId: "kbatx5i9s5yrieuz0jyx",
  type: "performance",
  title: "Performance Report",
  resultSummary: "2/12 checks passed",
  date: "2/8/2024",
  periodStart: "11/15/2023",
  periodEnd: "12/01/2023",
  lastUpdated: "2/10/2024",
  seenBy: [],
  items: [
    {
      id: "repo_ownership_setup",
      title: "Repo ownership/setup",
      description:
        "Client will host the repo on GitLab with a 3 repos for 1 frontend, 1 backed and 1 API",
      passed: true,
      actionItems: [],
    },
    {
      id: "standups",
      title: "Standups",
      description:
        "There should be one standup per working day that describes the work being done.",
      passed: false,
      actionItems: [
        "One standup was logged retroactively (more than a day late). Mobcoder has been informed of this and pledges to log standups on time moving forward.",
      ],
    },
    {
      id: "commit_frequency",
      title: "Commit Frequency",
      description: "At least 2 commits per day",
      passed: true,
      actionItems: [],
    },
    {
      id: "commit_messages",
      title: "Commit messages",
      description:
        "Commit messages should be descriptive enough to understand why the commit was made.",
      passed: true,
      actionItems: [],
    },
  ],
};

const testReports = [
  testReport1,
  testReport2,
  testReport3,
  testReport4,
  testReport5,
  testReport6,
];
