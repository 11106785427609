import React, { useState, useRef, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import { Popover } from "antd";
import ContentEditable from "react-contenteditable";
import TagCard from "./TagCard";
import "./TextField.scss";
import {
  getNewValue,
  getLatestTagPhrase,
  getPopupOffset,
  eventIsTagBackspace,
  shouldStartTagging,
  shouldStopTagging,
  getSelection,
  setCursor,
  convertToText,
} from "./helpers";
import { findNewCharacterIndex } from "../../utils";
import { useCollaboratorsForProject } from "../../../hooks/useCollborators";

export default function TextField({
  placeholder,
  onChange,
  value,
  autoSize,
  visible,
  filesToUpload,
  setFilesToUpload,
}) {
  // Collaborators are loaded from the current project to determine tag options
  const collaborators = useCollaboratorsForProject();

  const defaultTagState = {
    // Whether or not thetag popup is open
    active: false,
    // Options to select from tagging
    options: collaborators,
    // X and Y offset for the tag popup
    offset: [0, 0],
    // The index of the currently selected tag option
    selectedIndex: 0,
    // Index of the latest character in the textbox
    // This is important for determining where the user is trying to tag
    latestCharIndex: 0,
    // Save the cursor selection when the user starts tagging to restore when they're done
    selection: {
      offset: null,
      selectedChild: 0,
    },
  };

  const [tagState, setRawTagState] = useState(defaultTagState);
  // Convenience function to merge old/new states
  const setTagState = (newState) => {
    setRawTagState({
      ...tagState,
      ...newState,
    });
  };

  const textFieldRef = useRef<HTMLTextAreaElement>();

  /**
   * These refs are used to prevent handlers bound to elements from getting stale states
   */
  const selectedTagIndex = useRef(0);
  const tagOptions = useRef(collaborators);
  const latestCharIndexRef = useRef(0);
  const cursorSelectionRef = useRef(defaultTagState.selection);
  const valueRef = useRef(value);

  /**
   * Update refs on every load
   */
  useEffect(() => {
    selectedTagIndex.current = tagState.selectedIndex;
    tagOptions.current = tagState.options;
    latestCharIndexRef.current = tagState.latestCharIndex;
    cursorSelectionRef.current = tagState.selection;
    valueRef.current = value;
  });

  // Every time the tag changes shows/hides, update the cursor to the previously saved value
  useEffect(() => {
    if (cursorSelectionRef.current.selectedChild > -1) {
      setCursor(
        textFieldRef.current,
        cursorSelectionRef.current.offset,
        cursorSelectionRef.current.selectedChild
      );
    }

    textFieldRef.current?.focus();
  }, [tagState.active]);

  // If we close the comment drawer, stop any in-progress tagging
  useEffect(() => {
    if (!visible) {
      setTagState({
        active: false,
      });
    }
  }, [visible]);

  const is13Inch = useMediaQuery({ maxWidth: 1500 });

  /**
   * Main function to handle when user inputs text into the text box
   * @param {*} event
   */
  const localOnChange = (event) => {
    // Clean up any browser formatting and standardize new lines
    const newValue = convertToText(event?.target?.value, value);

    // Get the index of the latest character in the new value by diffing it with old
    const latestCharIndex = findNewCharacterIndex(value, newValue);

    if (!eventIsTagBackspace(event, value)) {
      // Get the latest tag phrase by finding everything between the latest character and the closet @
      const lastTagPhrase = getLatestTagPhrase(
        newValue,
        latestCharIndex
      )?.replace("@", "");

      // The new tag options are collaborators that have the tag phrase somewhere in their name
      const newTagOptions = collaborators.filter(
        (collaborator) =>
          !lastTagPhrase ||
          collaborator?.name
            ?.toLowerCase()
            .includes(lastTagPhrase?.toLowerCase())
      );

      if (
        !tagState.active &&
        shouldStartTagging(value, newValue, newTagOptions)
      ) {
        // Set state to start tagging
        setTagState({
          active: true,
          options: newTagOptions,
          offset: getPopupOffset(textFieldRef),
          latestCharIndex,
          selection: getSelection(textFieldRef.current),
        });
      } else if (
        tagState.active &&
        shouldStopTagging(event, newValue, newTagOptions, latestCharIndex)
      ) {
        // Reset tag state if we should stop tagging
        setTagState({
          active: false,
          options: collaborators,
          latestCharIndex,
          selection: getSelection(textFieldRef.current),
        });
      } else {
        // Otherwise, figure out the the new selectedOption and selectedIndex
        // relative to the new eligible options
        const selectedOption = tagState.options[selectedTagIndex.current];
        const newSelectedIndex = Math.max(
          newTagOptions?.findIndex((option) => option.id == selectedOption.id),
          0
        );

        // And update the tag state
        setTagState({
          options: newTagOptions,
          selectedIndex: newSelectedIndex,
          latestCharIndex,
          selection: getSelection(textFieldRef.current),
        });
      }
    }

    // Since we've handled all tagging logic, call the parents onChange
    onChange({ target: { value: newValue } });
  };

  /**
   * Function to handle the user selecting a tag, whether via click/enter/tab
   * @param {*} user
   */
  const selectTag = (user) => {
    // Get the new text field value with a <span> to render the user tag
    const newValue = getNewValue(
      valueRef.current,
      user,
      latestCharIndexRef.current
    );

    // Figure out on which child the cursor should be when tagging is closed
    const newSelectedChild =
      cursorSelectionRef.current.selectedChild === 0 &&
      newValue.startsWith("<span")
        ? 1
        : cursorSelectionRef.current.selectedChild + 2;

    // Execute tagging logic and eventually call parent on change to save text
    localOnChange({ target: { value: newValue } });

    // Update tag state with new offset state
    setTagState({
      ...defaultTagState,
      options: tagOptions.current,
      selection: {
        offset: 1,
        selectedChild: newSelectedChild,
      },
    });

    textFieldRef.current?.focus();
  };

  const handlePaste = (event: React.ClipboardEvent<HTMLDivElement>) => {
    event.preventDefault();

    const items = event.clipboardData.items;
    const files: File[] = [];

    for (let i = 0; i < items.length; i++) {
      const item = items[i];

      if (item.kind === "file" && item.type != "") {
        const file = item.getAsFile();
        if (file && file.size > 0) {
          files.push(file);
        }
      }
    }

    if (files.length > 0) {
      // Handle file upload
      handleFileUpload(files);
      event.preventDefault();
    } else {
      // Existing text paste logic
      const pasteString = event.clipboardData.getData("text/plain");
      const selection = window.getSelection();
      if (selection) {
        selection.deleteFromDocument();
        const range = selection.getRangeAt(0);
        range.insertNode(document.createTextNode(pasteString));
        selection.collapseToEnd();
      }
      if (textFieldRef.current) {
        localOnChange({ target: { value: textFieldRef.current.innerHTML } });
      }
    }
  };

  const handleFileUpload = (files: File[]) => {
    setFilesToUpload(files);
  };

  /**
   * Function to handle keypress when navigating tag options
   */
  const handleKeyDown = (event) => {
    if (tagState.active) {
      const currentIndex = selectedTagIndex.current;
      const currentOptions = tagOptions.current;

      switch (event.key) {
        case "ArrowUp":
          if (currentIndex > 0) {
            setTagState({
              selectedIndex: currentIndex - 1,
              options: currentOptions,
              latestCharIndex: latestCharIndexRef.current,
            });
          }
          event.preventDefault();
          break;

        case "ArrowDown":
          if (currentIndex < currentOptions.length - 1) {
            setTagState({
              selectedIndex: currentIndex + 1,
              options: currentOptions,
              latestCharIndex: latestCharIndexRef.current,
            });
          }
          event.preventDefault();
          break;

        case "Tab":
        case "Enter":
          selectTag(currentOptions[currentIndex]);
          event.preventDefault();
          textFieldRef.current?.focus();
          break;

        default:
          break;
      }
    }
  };

  return (
    <Popover
      align={{
        points: ["bc", "tc"],
        offset: tagState.offset,
      }}
      content={
        <div>
          {tagState.options.map((collaborator, index) => (
            <TagCard
              selected={tagState.selectedIndex === index}
              key={collaborator.id}
              user={collaborator}
              onClick={() => {
                selectTag(collaborator);
              }}
              onMouseEnter={() =>
                setTagState({
                  selectedIndex: index,
                })
              }
            />
          ))}
        </div>
      }
      visible={tagState.active}
    >
      <TextArea
        innerRef={textFieldRef}
        placeholder={placeholder}
        onChange={localOnChange}
        autoSize={autoSize}
        is13inch={is13Inch}
        html={value}
        onKeyDown={handleKeyDown}
        onPaste={handlePaste}
      />
    </Popover>
  );
}

const TextArea = styled(ContentEditable)`
  color: black;
  font-size: ${(props) => (props.is13inch ? "14px" : "16px")};
  font-family: Avenir Next;
  width: 100%;
  resize: none;
  padding: 10px;
  border: 2px solid rgb(240, 240, 240);
  min-height: 64px;
  background-color: #ffffff;
  border-radius: 4px;
  overflow: auto;
  white-space: pre-wrap;
  word-break: break-word;
  display: inline-block;
  &:focus {
    border: 1px solid #40a9ff;
    outline: 0;
    -webkit-box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);
    box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);
  }
  &:empty:before {
    content: "Add a comment";
    opacity: 0.15;
  }
`;
