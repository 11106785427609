import React, { useState } from "react";
import { Select } from "antd";
import { FieldProps } from "./types";

const { Option } = Select;

export default function TagField({
  field,
  valueEditable,
  checkPermissionsAndUpdateValue,
}: FieldProps<string>) {
  const [dropdownActive, setDropdownActive] = useState(false);

  const fieldMetadata = field.fieldData.options?.[valueEditable];

  if (!fieldMetadata) {
    return null;
  }

  return (
    <div>
      <div
        onClick={() => {
          setDropdownActive(!dropdownActive);
        }}
        className="table-tag"
        style={{
          color: fieldMetadata.color || "#000000",
          backgroundColor: fieldMetadata.backgroundColor || "#ffffff",
          borderColor: fieldMetadata.backgroundColor || "#000000",
        }}
      >
        {fieldMetadata.name}
      </div>

      <div
        style={{
          visibility: "hidden",
          width: "0px",
          height: "0px",
        }}
      >
        <Select
          value={valueEditable}
          style={{ width: 170, height: 0 }}
          open={dropdownActive}
          onDropdownVisibleChange={setDropdownActive}
          onChange={checkPermissionsAndUpdateValue}
          dropdownAlign={{ offset: [0, -30] }}
        >
          {field.fieldData.options_order &&
            Object.values(field.fieldData.options_order).map((optionId) => (
              <Option key={optionId} value={optionId}>
                {field.fieldData.options?.[optionId]?.name ||
                  "ERROR - UNDEFINED"}
              </Option>
            ))}

          {!field.fieldData.options_order &&
            Object.values(field.fieldData.options ?? {}).map((option) => (
              <Option key={option.id} value={option.id}>
                {option?.name || "ERROR - UNDEFINED"}
              </Option>
            ))}
        </Select>
      </div>
    </div>
  );
}
