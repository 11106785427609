import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { getWidthForFieldType, allowAction, deepEqualCheck } from "../../utils";
import _ from "lodash";
import { message, Tooltip } from "antd";

import UserField from "./UserField";
import CollaboratorField from "./CollaboratorField";
import DateField from "./DateField";
import TagField from "./TagField";
import Checkbox from "./Checkbox";
import PasswordField from "./PasswordField";
import LinkField from "./LinkField";
import TextField from "./TextField";
import TextArea from "./TextArea";
import AttachmentsField from "./AttachmentsField";
import ReferenceField from "./ReferenceField";
import NumberField from "./NumberField";

import { useMediaQuery } from "react-responsive";

import { TbSubtask } from "react-icons/tb";
import "./Fields.scss";
import TableRowCommentsMini from "../TableRowCommentsMini";
import TableRowDropdownMini from "../TableRowDropdownMini";
import { useUserRole } from "../../../hooks";
import { AppState } from "@/types";
import LastUpdatedField from "./LastUpdatedField";

// As new fields are created that care about hover
// behavior, add them here. This reduces state updates
// if a field doesn't actually care about hovering
const HOVER_FIELD_TYPES = ["link"];

function TableField({
  field,
  value,
  updateValue,
  isIndexRow,
  objectId = "undefinedObjectId",
  project,
  collectionKey,
  created_by_user,
  last_updated_timestamp,
  components,
  subtasksExpanded = false,
  setSubtasksExpanded,
  subtasks,
  object,
}) {
  const is13Inch = useMediaQuery({ maxWidth: 1500 });
  // This is mainly used for text fields
  const [activelyEditing, setActivelyEditing] = useState(false);

  const [hoverActive, setHoverActive] = useState(false);
  const roles = useSelector((state: AppState) => state.roles[project.id]);

  const [valueEditable, setValueEditable] = useState(
    value != null ? value : field.fieldData.defaultValue
  );

  const [valueOriginal, setValueOriginal] = useState(
    value != null ? value : field.fieldData.defaultValue
  );

  // Get the current user from the store
  const currentUserRole = useUserRole(project.id);
  const wrapperRef = useRef<HTMLDivElement>(null);

  // Check permissions and allow user to edit the value or display an error message
  function checkPermissionsAndUpdateValue(value) {
    var canEdit = false;

    if (
      roles[currentUserRole]?.permissions?.sections[collectionKey]?.fields[
        field.valueKey
      ]?.edit
    ) {
      canEdit = true;
    }

    if (
      roles[currentUserRole]?.permissions?.sections[collectionKey]?.fields[
        field.valueKey
      ]?.edit == null
    ) {
      // If null, check the component permissions

      if (roles[currentUserRole]?.permissions?.sections[collectionKey]?.edit) {
        canEdit = true;
      }
    }

    if (canEdit) {
      // If this is not a text field, send the update immediately
      if (!activelyEditing) {
        setValueEditable(value);
        setValueOriginal({ ...valueEditable });
        updateValue(value, field.valueKey);
      } else {
        // If its a text field see the useEffect below that only sends updates when editing is inactive
        setValueEditable(value);
      }
    } else {
      message.warning("You don't have permission to edit " + field.title);
    }
  }

  // This is only for text fields
  useEffect(() => {
    // Send an update if editing is not active
    if (!activelyEditing && valueOriginal != valueEditable) {
      updateValue(valueEditable, field.valueKey);
      setValueOriginal({ ...valueEditable });
    }
  }, [activelyEditing]);

  // If value is updated and is out of sync with valueEditable, we will
  // update valueEditable (this happens with remote changes and realtime)
  useEffect(() => {
    const valueToSet = value != null ? value : field.fieldData.defaultValue;
    if (!_.isEqual(valueToSet, valueEditable)) {
      setValueOriginal({ ...valueEditable });
      setValueEditable(valueToSet);
    }
  }, [value]);

  useEffect(() => {
    const stopPropagation = (event) => {
      if (wrapperRef.current && wrapperRef.current.contains(event.target)) {
        event.stopPropagation();
      }
    };

    if (activelyEditing) {
      document.addEventListener("mousedown", stopPropagation, true);
    }
    return () =>
      document.removeEventListener("mousedown", stopPropagation, true);
  }, [activelyEditing]);

  // console.log("field is ", field)
  if (!field.fieldData) {
    return <div>Error: missing field data</div>;
  }

  // Combine common props for all field, and pass later to individual field implementations
  // Saves us from having to copy this every single time
  const fieldProps = {
    field,
    valueEditable,
    setValueEditable,
    activelyEditing,
    setActivelyEditing,
    hoverActive,
    checkPermissionsAndUpdateValue,
    project,
    roles,
    components,
    currentObjectId: objectId,
    collectionKey,
  };

  const fieldType = field.fieldData.type;
  const hasSubtasks = subtasks && Object.keys(subtasks).length > 0;

  return (
    <div
      className={`table-cell ${field.legacy ? "legacy" : ""} ${isIndexRow ? "index-row" : ""
        } ${!activelyEditing ? "cell-active" : ""}`}
      onMouseEnter={() => {
        if (HOVER_FIELD_TYPES.includes(fieldType)) {
          setHoverActive(true);
        }
      }}
      onMouseLeave={() => {
        if (HOVER_FIELD_TYPES.includes(fieldType)) {
          setHoverActive(false);
        }
      }}
      ref={wrapperRef}
      style={getWidthForFieldType(fieldType, is13Inch)}
      data-cy={`${objectId}_${field.valueKey}`}
    >
      {isIndexRow && (
        <div className="ext-mini flex flex-row items-center justify-start h-8 w-5 ml-3 text-neutral-300">
          {object.ext_id || 0}
        </div>
      )}
      {isIndexRow && (
        <TableRowDropdownMini
          object={object}
          projectId={project.id}
          component={components[collectionKey]}
        />
      )}

      {fieldType === "text" && <TextField {...fieldProps} key={field.id} />}

      {fieldType === "textArea" && (
        <TextArea
          {...fieldProps}
          key={field.id}
          isIndexRow={isIndexRow}
          subtaskRow={isIndexRow}
        />
      )}

      {fieldType === "link" && <LinkField {...fieldProps} />}

      {fieldType === "password" && <PasswordField {...fieldProps} />}

      {fieldType === "number" && <NumberField {...fieldProps} />}

      {(fieldType === "tag" || fieldType === "select") && (
        <TagField {...fieldProps} />
      )}

      {fieldType === "checkbox" && <Checkbox {...fieldProps} />}

      {(fieldType === "date" || fieldType === "date_with_age") && (
        <DateField {...fieldProps} isIndexRow={isIndexRow} />
      )}

      {fieldType === "user" && (
        <UserField {...fieldProps} created_by_user={created_by_user} />
      )}

      {fieldType === "collaborator" && (
        <CollaboratorField projectId={project.id} {...fieldProps} />
      )}

      {fieldType === "attachments" && (
        <AttachmentsField projectId={project.id} {...fieldProps} />
      )}

      {fieldType === "reference" && (
        <ReferenceField projectId={project.id} {...fieldProps} />
      )}

      {fieldType === "last_updated" && (
        <LastUpdatedField
          projectId={project.id}
          last_updated_timestamp={last_updated_timestamp}
        />
      )}

      {collectionKey == "status_items" && isIndexRow && hasSubtasks && (
        <div
          onClick={() => {
            setSubtasksExpanded(!subtasksExpanded);
          }}
          className={`h-8 w-11 cursor-pointer flex flex-row gap-1 items-center p-1 mr-1 text-[12px] rounded-md hover:bg-neutral-100 text-neutral-400 hover:text-neutral-800 ${subtasksExpanded ? "text-[#7f05f5]" : ""
            }`}
        >
          <TbSubtask className="h-4 w-4" />
          {Object.keys(subtasks).filter((key) => subtasks[key].complete).length}
          /{Object.keys(subtasks).length}
        </div>
      )}

      {collectionKey == "status_items" && isIndexRow && !hasSubtasks && (
        <div
          onClick={() => {
            setSubtasksExpanded(!subtasksExpanded);
          }}
          className={`subtask-mini h-8 w-6 cursor-pointer flex flex-row gap-1 items-center p-1 mr-1 text-[12px] rounded-md hover:bg-neutral-100 text-neutral-400 hover:text-neutral-800 ${subtasksExpanded ? "text-[#7f05f5]" : ""
            }`}
        >
          <TbSubtask className="h-6 w-6" />
        </div>
      )}

      {isIndexRow && (
        <TableRowCommentsMini
          object={object}
          projectId={project.id}
          allowComments={true}
          component={components[collectionKey]}
          setTableRowHighlighted={() => { }}
        />
      )}
    </div>
  );
}

export default React.memo(TableField, _.isEqual);
